import React, { useState, useEffect } from "react";
import axios from 'axios';
import DictionaryDisplay from './DictionaryDisplay';
import BackupDictionaryDisplay from './BackupDictionaryDisplay'; // Import the new component

function DictionaryInputField() {
  const [dictionaryInput, setDictionaryInput] = useState('');
  const [responseData, setResponseData] = useState([
    {
      "full_word": "言葉",
      "word_kana": "ことば",
      "accent_codes": "3"
    }
  ]);
  const [fullWord, setFullWord] = useState([]); // State to store the full word from the file

  const fetchData = () => {
    console.log('Dictionary Input:', dictionaryInput);
    if (dictionaryInput) {
      axios.get(`http://localhost:9000/api/words/${dictionaryInput}`)
        .then(response => {
          console.log('Server response:', response.data);
          const data = Array.isArray(response.data) ? response.data : [response.data];
          setResponseData(data);
        })
        .catch(error => {
          console.error('Error loading accent info:', error);
          setResponseData(null); // Set responseData to null in case of error
        });
    }
  };

  // This useEffect runs when dictionaryInput changes and there's no data returned. 
  useEffect(() => {
    if (!responseData || responseData.length === 0) {
      let foundMatchesCount = 0; // Initialize a counter for found matches
      const maxMatches = 15 // Set the maximum number of matches to find
  
      // Load pitch accent information from the file based on dictionaryInput
      fetch('raw_accents.txt')
        .then(response => response.text())
        .then(accents => {
          let wordInfo = '';
  
          let matches = accents.matchAll(/^([^\t]+(?:\t[^\t]+)?)\t(.+)$/gm);
  
          // Initialize an empty array to store matched words
          const matchedWordsArray = [];
  
          for (const match of matches) {
            const key = match[0];
            const values = key.split('\t').map(value => value.trim());
  
            if (values.some(value => value === dictionaryInput.trim())) {
              const wordInfoObject = {
                full_word: values[0],
                word_kana: values[1] !== '' ? values[1] : values[0],
                accent_codes: values[values.length - 1],
              };
              wordInfo = wordInfoObject;
  
              matchedWordsArray.push(wordInfoObject);
  
              foundMatchesCount++; // Increment the counter for found matches
  
              if (foundMatchesCount >= maxMatches) {
                // Break out of the loop when the desired number of matches is reached
                break;
              }
            }
          }
  
          console.log('Word Info:', wordInfo);
  
          if (matchedWordsArray.length > 0) {
            // Set fullWord to the array of matched words
            setFullWord(matchedWordsArray);
          }
        })
        .catch(error => {
          console.error('Error loading pitch info with backup method:', error);
        });
    }
  }, [dictionaryInput, responseData]);

  useEffect(() => {
    fetchData();
  }, []); // Fetch data when component mounts

  return (
    <div className="dictionary_component_container">
      <div className="input">
        {/* Your input field */}
        <input 
          placeholder='単語を入力してください'
          className="input__box"
          type="input"
          value={dictionaryInput}
          onChange={e => {
            console.log('Input changed:', e.target.value);
            setDictionaryInput(e.target.value);
          }}
        />
        {/* Fetch button */}
        <button className="input_submit" onClick={fetchData}>GO</button>
      </div>
      <div className="dictionary-display">
        {responseData !== null
          ? responseData.map((data, index) => (
              <DictionaryDisplay key={index} responseData={data} />
            ))
          : null}
      </div>
      {responseData === null && fullWord.length > 0 && (
        <div className="dictionary-display">
          {fullWord.map((word, index) => (
            <BackupDictionaryDisplay key={index} fullWord={word} />
          ))}
        </div>
      )}
    </div>
  );
}

export default DictionaryInputField;


/* 
Old code without backup route:


import React, { useState, useEffect } from "react";
import axios from 'axios';
import DictionaryDisplay from './DictionaryDisplay';

function DictionaryInputField() {
  const [dictionaryInput, setDictionaryInput] = useState('');
  const [accentCodes, setAccentCodes] = useState(null);
  const [responseData, setResponseData] = useState([
    {
      "full_word": "言葉",
      "word_kana": "ことば",
      "accent_codes": "3"
    }
  ]); // State to store response data as an array of objects

  const fetchData = () => {
    if (dictionaryInput) {
      axios.get(`http://localhost:8000/api/words/${dictionaryInput}`)
        .then(response => {
          console.log('Server response:', response.data);
          const data = Array.isArray(response.data) ? response.data : [response.data];
          setResponseData(data);
        })
        .catch(error => {
          console.error('Error loading accent info:', error);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Fetch data when component mounts

  return (
    <div className="dictionary_component_container">
      <div className="input">
      {/* Your input field }
      <input 
        placeholder='単語を入力してください'
        className="input__box"
        type="input"
        value={dictionaryInput}
        onChange={e => {
          console.log('Input changed:', e.target.value);
          setDictionaryInput(e.target.value);
        }}
      />
      {/* Fetch button }
      <button className="input_submit" onClick={fetchData}>GO</button>
      </div>
      <div className="dictionary-display">
        {responseData.map((data, index) => (
          <DictionaryDisplay key={index} responseData={data} />
        ))}
      </div>
    </div>
  );
}

export default DictionaryInputField; */