// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import firebase from "firebase/compat/app";
// Required for side-effects
import { getFirestore } from "firebase/firestore";
// Initialize firestore once. I'm not sure this is the best place for this yet.

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDfJTXUFVZPdnjiAJ-XmAcvOzFQZOM3A-A",
  authDomain: "pitchtuna.firebaseapp.com",
  projectId: "pitchtuna",
  storageBucket: "pitchtuna.appspot.com",
  messagingSenderId: "1080891066042",
  appId: "1:1080891066042:web:34b950e14e31804793a483",
  measurementId: "G-P7RQ2GZQC2"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
 const db = getFirestore(app);

 export {db}



