import React, { useState, useEffect } from 'react';
import GetAccentInfo from './GetAccentInfo'; 

export default function Flashcard({ flashcard }) {
    const [flip, setFlip] = useState(false);

      // Watch for changes in the 'flashcard' prop and reset 'flip' state
  useEffect(() => {
    setFlip(false); // Reset to the front when 'flashcard' prop changes
  }, [flashcard]);

    return (
        <div className={`card ${flip ? 'flip' : ''}`} onClick={() => setFlip(!flip)}>
            {flip ? (
                <div className='back'>
                    <div className='back-pitch-info'>
                     <div className='pitch-accent-display'>
                     <GetAccentInfo flashcard={flashcard} />
                     </div>
                     </div>
                    <div className='definition'>
                        Definition: {flashcard.definition}
                    </div>
                </div>
            ) : (
                <div className='front'>
                    <div className='front-header'>
                        {flashcard.full_word}
                     </div>
                </div>
            )}
        </div>
    );
            };





