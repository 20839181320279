import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';
import FlashcardList from './FlashcardList';
import DictionaryComponent from './DictionaryComponent';
import {db} from "./firebase"
import { collection, addDoc, where, query, getDocs, doc, getDoc, limit } from "firebase/firestore"; // Import Firestore functions
import ClientInputForm from './ClientInputForm'; // getting this off the main app.js file. Currently this isn't being used for anything
import FetchFlashcards from './FetchFlashcards';


function App() {
  const [flashcardsArray, setFlashCardArray] = useState(SAMPLE_CARDS);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(19949); //figure out an inital value later maybe.
  

  useEffect(() => {
    // Fetch categories from Firestore
    const fetchCategories = async () => {
      try {
        const categoryQuery = query(collection(db, "categories"));
        const categorySnapshot = await getDocs(categoryQuery);
        const categoryData = categorySnapshot.docs.map(doc => doc.data());

      // Log the retrieved data to the console
      console.log("Fetched categories:", categoryData);

        setCategories(categoryData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    }; 
  
    fetchCategories();
  }, []); //this is working now

/* Old code for pg database
  useEffect(() => {
    axios.get('http://localhost:8000/api/categories')
      .then(response => {
        setCategories(response.data);
        console.log('setCategories response: ', response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);
  */

 
  /* old flashcardarray get
  useEffect(() => {
    if (selectedCategoryId) {
      axios.get(`http://localhost:8000/api/word_categories_junction/${selectedCategoryId}`)
        .then(response => {
          setFlashCardArray(response.data);
          console.log('setFlashCardArray response:', response.data);
        })
        .catch(error => {
          console.error('Error fetching selected category words:', error);
        });
    }
  }, [selectedCategoryId]);
  */

  const handleCategoryChange = event => {
    const categoryId = event.target.value;
    setSelectedCategoryId(categoryId);
    console.log('Selected Category ID:', categoryId); //let's check this first. debug
  };
  
  return (
    <div className="parent-body">
      <header className='page-head'>
      <div className='title_and_logo_box'>
      <img src="/evan_fish2@0.5x.png" className='tuna_logo' alt="Logo" />
        <h1 className='title'>PitchTuna - Japanese Pitch Accent Dictionary and Flashcards</h1>
        </div>  
        <h2 className='sub-title'> Learn Japanese vocab the correct way - with the proper pitch accent</h2>
        <p className='header-info'>On this app you can learn how to speak Japanese words as a native speaker does, with correct pitch accent. 
          Studying correct pitch accent will allow you to lose the "foreign accent" over time and avoid developing entrenched mistakes that are very difficult to unlearn later.
          Perhaps surprisingly, studying pitch accent also greatly improves your listening comprehension, even for relatively advanced learners.
          Use the dictionary on the left to check the pitch pattern for any word in Japanese. Or study with flashcards on the right.
          These flashcards have the target word on the front and tell you the pitch pattern and other info for word on the back. Just click to flip!</p>
      </header>
      <div className='left-side-dictionary-components-container'>
        <div className='upper_components_container'>
        <h2 className='dictionary_header'>JAPANESE PITCH ACCENT DICTIONARY</h2>
          <DictionaryComponent />
        </div>
      </div>
      <main className="main-flashcard-container"> 
      <div className='selector_components_container'>
          <h2 className='selector_header'>SELECT FLASHCARDS CATEGORY</h2>
          <select value={selectedCategoryId} onChange={handleCategoryChange}>
            <option value="">Select a category</option>
            {categories.map(category => (
              <option key={category.category_id} value={category.category_id}>
                {category.category_name}
              </option>
            ))}
          </select>
        </div>
        <FetchFlashcards selectedCategoryId={selectedCategoryId}/>
      </main>
  <footer className="footer">
    
  <div>
 <ClientInputForm />
</div>
  <p className="footer-text">2023 Copyright © PitchTuna LLC</p>
</footer>
    </div>
  );
}

const SAMPLE_CARDS = [
  {
    id: 1,
    full_word: '教える',
    word_kana: 'おしえる',
    accent_codes: null,
    definition: 'to teach',
    options: [
      'Forgot',
      'Hard',
      'Got It',
      'Easy',
  ]
  },
  {
    id: 2,
    full_word: '中央',
    word_kana: 'ちゅうおう',
    accent_codes: null,
    definition: 'centre, center, middle, heart, metropolis, capital city, central government',
    options: [
      'Forgot',
      'Hard',
      'Got It',
      'Easy',
  ]
  },
   {
    id: 3,
    full_word: '動物園',
    word_kana: 'どうぶつえん',
    accent_codes: null,
    definition: 'Zoo, zoological gardens',
    options: [
      'Forgot',
      'Hard',
      'Got It',
      'Easy'
  ]
  },
  {
    id: 4,
    full_word: '浴びる',
    word_kana: 'あびる',
    accent_codes: null,
    definition: 'to take (e.g. shower), to bask in (e.g. the sun), to bathe in, to be flooded with (e.g. light)',
    options: [
      'Forgot',
      'Hard',
      'Got It',
      'Easy'
  ]
  },
  {
    id: 5,
    full_word: '船',
    word_kana: 'ふね',
    accent_codes: null,
    definition: 'boat',
    options: [
      'Forgot',
      'Hard',
      'Got It',
      'Easy'
  ]
  },
  {
    id: 6,
    full_word: '気',
    word_kana: 'き',
    accent_codes: null,
    definition: 'spirit, mind, heart, nature, disposition, motivation, intention, mood, feeling',
    options: [
      'Forgot',
      'Hard',
      'Got It',
      'Easy'
  ]
  },
  {
    id: 7,
    full_word: '所',
    word_kana: 'ところ',
    accent_codes: null,
    definition: 'place, spot, scene, site, address, district, area, locality',
    options: [
      'Forgot',
      'Hard',
      'Got It',
      'Easy'
  ]
  },
  {
    id: 8,
    full_word: 'バス',
    word_kana: 'バス',
    accent_codes: null,
    definition: 'bus, bath, bass',
    options: [
      'Forgot',
      'Hard',
      'Got It',
      'Easy'
  ]
  },
  {
    id: 9,
    full_word: '社会',
    word_kana: 'しゃかい',
    accent_codes: null,
    definition: 'society, public, community, the world',
    options: [
      'Forgot',
      'Hard',
      'Got It',
      'Easy'
  ]
  },
  {
    id: 10,
    full_word: '書く',
    word_kana: 'かく',
    accent_codes: null,
    definition: 'to write',
    options: [
      'Forgot',
      'Hard',
      'Got It',
      'Easy'
  ]
  }

]


export default App;


/* Old fetch for flashcards

 useEffect(() => {
    if (selectedCategoryId) {
      // Define a reference to the "word_categories_junction" collection
      const junctionCollection = collection(db, "word_categories_junction");
      console.log('Junction Collection:', junctionCollection); // Log the collection reference
  
      // Create a query to fetch documents that match the selected category
      const categoryQuery = query(
        junctionCollection,
        where("category_id", "==", parseInt(selectedCategoryId)),
        limit (10)
      );
  
      // Fetch documents from Firestore
      const fetchWordJunctions = async () => {
        const chunkSize = 10; // Define chunkSize within the function
        try {
          const junctionSnapshot = await getDocs(categoryQuery);
          const junctionData = junctionSnapshot.docs.map(doc => doc.data());
  
          console.log('Selected Category ID:', selectedCategoryId);
          console.log('Word Categories Junction Data:', junctionData);
  
          const wordIds = junctionData.map(junction => parseInt(junction.word_id, 10));
          console.log('Word IDs:', wordIds);
  
          // Split the wordIds into chunks of less than 30 to get around the limitation on the 'in' operation
          const wordIdChunks = [];
for (let i = 0; i < wordIds.length; i += chunkSize) {
  wordIdChunks.push(wordIds.slice(i, i + chunkSize));
}
console.log('Word ID Chunks:', wordIdChunks);

const wordCollection = collection(db, "flashcards");
console.log('Word Collection Reference:', wordCollection); // Log the collection reference

// Fetch and concatenate word documents for each chunk
const wordData = [];
for (const chunk of wordIdChunks) {
  const wordQuery = query(wordCollection, where("serial_key", "in", chunk));
  console.log('Word Query:', wordQuery); // Log the query

  try {
    const wordSnapshot = await getDocs(wordQuery);
    console.log('Word Snapshot:', wordSnapshot); // Log the snapshot

    const wordDocs = wordSnapshot.docs.map(doc => doc.data());
    console.log('Fetched word documents app.js:', wordDocs); // Log the fetched word documents

    wordData.push(...wordDocs);
  } catch (error) {
    console.error('Error fetching selected category words:', error);
  }
}

setFlashCardArray(wordData);
console.log('setFlashCardArray response:', wordData);
        } catch (error) {
          console.error('Error fetching selected category words:', error);
        }
      };
  
      fetchWordJunctions();
    }
  }, [selectedCategoryId]);
*/