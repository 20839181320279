import React, { useEffect, useState } from 'react';
import GetAccentInfo from './GetAccentInfo';



// Defined constants (adjust these as needed)
const STROKE_WIDTH = 0.08;
const DOT_RADIUS = 0.11;
const WEB_URL = 'http://www.gavo.t.u-tokyo.ac.jp/ojad/search/index/curve:fujisaki/word:%s';
const DICT_URL = 'https://jisho.org/search/%s';
const ACCENT_URL = 'https://forvo.com/word/%s/#ja';


// Get the color and the pitch pattern name
let patternObj = {
    heiban: {
      name: '平板',
      nameEng: 'heiban',
      cssClass: 'heiban',
      color: '#d20ca3',
    },
    odaka: {
      name: '尾高',
      nameEng: 'odaka',
      cssClass: 'odaka',
      color: '#0cd24d',
    },
    nakadaka: {
      name: '中高',
      nameEng: 'nakadaka',
      cssClass: 'nakadaka',
      color: '#27a2ff',
    },
    atamadaka: {
      name: '頭高',
      nameEng: 'atamadaka',
      cssClass: 'atamadaka',
      color: '#EA9316',
    },
    unknown: {
      name: '不詳',
      nameEng: 'No pitch value found, click the number for more info.',
      cssClass: 'unknown',
      color: '#CCCCCC',
    },
  };


  const DisplayAccent = ({ flashcard, accentCode }) => {
    const [moraCount, setMoraCount] = useState(0);
    console.log('mora count: ', moraCount); //debug 
    console.log('passed further down from GetAccentInfo: flashcard data: ', flashcard); //debug 
    function getMoraCount(kana) {
      let digraphs = 'ぁぃぅぇぉゃゅょゎゕゖァィゥェォャュョヮヵヶ';
      return [...kana].filter(c => !digraphs.includes(c)).length;
    }

  
    useEffect(() => {
      const kana = flashcard.word_kana;
      const calculatedMoraCount = getMoraCount(kana);
  
      setMoraCount(calculatedMoraCount);
    }, [flashcard.word_kana]);
  
    const getPitchType = (accentCode, moraCount) => {
      if (accentCode === 0) return patternObj.heiban;
      if (accentCode === 1) return patternObj.atamadaka;
      if (accentCode === moraCount) return patternObj.odaka;
      if (accentCode < moraCount) return patternObj.nakadaka;
      return patternObj.unknown;
    };
  
    const generatePatternText = (accentCode, kana) => {
      const moraCount = getMoraCount(kana);
      const patternType = getPitchType(accentCode, moraCount);
  
      return (
        <div>
          <span className={patternType.cssClass}>
            PITCH PATTERN: {patternType.name + ' '}
          </span>
          <a
            href={WEB_URL.replace('%s', flashcard.full_word)}
            target="_blank"
            rel="noreferrer"
            title={`Pitch Pattern: ${patternType.nameEng} (${patternType.name})`}
            style={{ color: patternType.color }}
          >
            [{accentCode}]
          </a>
          <a
          href={ACCENT_URL.replace('%s', flashcard.full_word)}
          target="_blank"
          rel="noreferrer"
          title={`pronunciaton`}
          style={{ display: 'block', marginTop: '0.5rem' }}
        >pronunciation
        </a>
          <a
          href={DICT_URL.replace('%s', flashcard.full_word)}
          target="_blank"
          rel="noreferrer"
          title={`definition`}
          style={{ display: 'block', marginTop: '0.5rem' }}
        >definition
        </a>
        </div>
      );
    };


  
      const generateSvg = (accentCode, kana) => {
        const moraCount = getMoraCount(kana);
        const width = moraCount;
      
        const patternType = getPitchType(accentCode, moraCount);
      
        const digraphs = 'ぁぃぅぇぉゃゅょゎゕゖァィゥェォャュョヮヵヶ';
        const xCoords = [];
        
        for (let i = 0; i <= kana.length; i++) {
          if (digraphs.includes(kana[i])) {  
            xCoords[xCoords.length - 1] += 0.125;
          } else {
            xCoords.push(i);
          }
        }
        
        const yCoords = new Array(moraCount + 1).fill(null).map((_, i) => {
          if (accentCode === 0) return i === 0 ? 1 : 0;
          if (i + 1 === accentCode) return 0;
          if (i === 0) return 1;
          return i < accentCode ? 0 : 1;
        });
      
        const points = yCoords.map((y, i) => ({ x: xCoords[i], y }));
      
        return (
          <svg viewBox={`-0.1 -0.25 ${width + 2.6} 1.5`} className={`accent-svg-${moraCount}mora`}>
            {/* Polyline */}
            <polyline
              fill="none"
              stroke={patternType.color} // Use patternType's color
              strokeWidth={STROKE_WIDTH}
              points={points.map(p => `${p.x},${p.y}`).join(' ')}
            />
      
            {/* Circles */}
            {points.map((p, i) => (
              <circle
                key={i}
                fill={i === points.length - 1 ? '#eeeeee' : patternType.color}
                stroke={i === points.length - 1 ? 'black' : patternType.color}
                strokeWidth={i === points.length - 1 ? STROKE_WIDTH / 2 : 0}
                cx={p.x}
                cy={p.y}
                r={DOT_RADIUS}
              />
            ))}
          </svg>
        );
      };
  
      const accentCodes = accentCode ? accentCode.split(',').map(Number) : [];

  return (
    <div className='accent-display-container'>
      <div className='backside-word' lang='ja'>{flashcard.full_word}</div>
      {accentCodes.map((code, index) => (
        <div key={index}>
          {generateSvg(code, flashcard.word_kana)}
          <div className='kana' lang='ja'>{flashcard.word_kana}</div>
          <div className='pattern-text'>{generatePatternText(code, flashcard.word_kana)}</div>
        </div>
      ))}
    </div>
  );
}; //consider making a dynamic class to account for the number for mora and then set width on the card according to that. update: did that and it worked.
 
  
  export default DisplayAccent;