import React, { useState, useEffect } from 'react';
import { collection, query, where, limit, startAfter, getDocs } from 'firebase/firestore';
import FlashcardList from './FlashcardList';
import { db } from './firebase';

function FetchFlashcards({ selectedCategoryId }) {
  const [flashcardsArray, setFlashCardArray] = useState([]);
  const [nextFlashcardsArray, setNextFlashCardArray] = useState([]); // State for the next batch
  const [lastVisible, setLastVisible] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false); // State to track button click
  const [showInitialFlashcards, setShowInitialFlashcards] = useState(true); // State to control rendering

  console.log('initial state selected CategoryID:', selectedCategoryId);

  useEffect(() => {
    setShowInitialFlashcards(true); // Hide initial flashcards when the button is clicked
  }, [selectedCategoryId]);
  

  useEffect(() => {
    if (selectedCategoryId) {
      const junctionCollection = collection(db, 'word_categories_junction');
      console.log('Junction Collection FFs firstUE:', junctionCollection);

      // Create the initial query
      const initialQuery = query(
        junctionCollection,
        where('category_id', '==', parseInt(selectedCategoryId)),
        limit(1)
      );

      console.log('Initial Query FFs firstUE:', initialQuery);

      // Fetch documents from Firestore
      const fetchData = async () => {
        try {
          const junctionSnapshot = await getDocs(initialQuery);
          console.log('Junction Snapshot firstUE:', junctionSnapshot); // Log the junctionSnapshot

          const junctionData = junctionSnapshot.docs.map(doc => doc.data());
          console.log('Junction Data firstUE:', junctionData); // Log the junctionData

          if (junctionSnapshot.docs.length > 0) {
            setLastVisible(junctionSnapshot.docs[junctionSnapshot.docs.length - 1]);
            console.log('Intial Last Visible ID firstUE: ', junctionSnapshot.docs[junctionSnapshot.docs.length - 1].id);
          }

          console.log('Selected Category ID FFS firstUE:', selectedCategoryId);
          console.log('Word Categories Junction Data FFs firstUE:', junctionData);

          const wordIds = junctionData.map(junction => parseInt(junction.word_id, 10));
          console.log('Initial Word IDs FFs firstUE:', wordIds);


          // Continue with the logic to fetch flashcards using wordIds
          const wordCollection = collection(db, 'flashcards');
          const wordQuery = query(
            wordCollection,
            where('serial_key', 'in', wordIds),
            limit(1)
          );

          const wordSnapshot = await getDocs(wordQuery);
          console.log('Word Snapshot FFs firstUE:', wordSnapshot);

          // Extract data from the wordSnapshot
          const wordDocs = wordSnapshot.docs.map(doc => doc.data());
          console.log('Fetched word documents firstUE:', wordDocs);

          // Update the flashcardsArray state
          setFlashCardArray(wordDocs);
        } catch (error) {
          console.error('Error fetching word junctions FFs firstUE:', error);
        }
      };

      fetchData();
    }
  }, [selectedCategoryId]);

  // Fetch more flashcards when buttonClicked state changes
  useEffect(() => {
    if (lastVisible) {
      const junctionCollection = collection(db, 'word_categories_junction');
      console.log('Fetching more flashcards using lastVisible:', lastVisible);

      // Create a query starting after the lastVisible document
      const nextQuery = query(
        junctionCollection,
        where('category_id', '==', parseInt(selectedCategoryId)),
        startAfter(lastVisible), // Use startAfter with lastVisible
        limit(1)
      );

      console.log('Next Query FFs:', nextQuery);

      // Fetch the next batch of junction data
      const fetchNextData = async () => {
        try {
          const junctionSnapshot = await getDocs(nextQuery);
          const junctionData = junctionSnapshot.docs.map(doc => doc.data());

          console.log('Fetched next batch of Word Categories Junction Data:', junctionData);

          const wordIds = junctionData.map(junction => parseInt(junction.word_id, 10));
          console.log('Word IDs for the next batch:', wordIds);

          setLastVisible(junctionSnapshot.docs[junctionSnapshot.docs.length - 1]);
          

          // Continue with the logic to fetch flashcards for the next batch using wordIds
          const wordCollection = collection(db, 'flashcards');
          const wordQuery = query(
            wordCollection,
            where('serial_key', 'in', wordIds),
            limit(1)
          );

          const wordSnapshot = await getDocs(wordQuery);
          console.log('Word Snapshot for the next batch:', wordSnapshot);

          // Extract data from the wordSnapshot for the next batch
          const wordDocs = wordSnapshot.docs.map(doc => doc.data());
          console.log('Fetched word documents for the next batch:', wordDocs);

          // Update the nextFlashcardsArray state
          setNextFlashCardArray(wordDocs);
        } catch (error) {
          console.error('Error fetching word junctions for the next batch:', error);
        }
      };

      fetchNextData();
    }
  }, [buttonClicked]);

  // Function to handle button click
  const handleButtonClick = () => {
    setButtonClicked(!buttonClicked);
    setShowInitialFlashcards(false); // Hide initial flashcards when the button is clicked
  };

  return (
    <div>
      {showInitialFlashcards ? (
        <FlashcardList flashcards={flashcardsArray} />
      ) : (
        <FlashcardList flashcards={nextFlashcardsArray} />
      )}
      <button className="load_next_button" onClick={handleButtonClick}>Next Card</button>
    </div>
  );
}

export default FetchFlashcards;

/*
// Query the first page of docs
const first = query(collection(db, "cities"), orderBy("population"), limit(25));
const documentSnapshots = await getDocs(first);

// Get the last visible document
const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
console.log("last", lastVisible);

// Construct a new query starting at this document,
// get the next 25 cities.
const next = query(collection(db, "cities"),
    orderBy("population"),
    startAfter(lastVisible),
    limit(25)); */
    


/*
      // Fetch documents from Firestore
      const fetchWordData = async () => {
        const chunkSize = 10; // Define chunkSize within the function
        try {
          const junctionSnapshot = await getDocs(categoryQuery);
          const junctionData = junctionSnapshot.docs.map(doc => doc.data());

          console.log('Selected Category ID:', selectedCategoryId);
          console.log('Word Categories Junction Data:', junctionData);

          const wordIds = junctionData.map(junction => parseInt(junction.word_id, 10));
          console.log('Word IDs:', wordIds);

          // Split the wordIds into chunks of less than 30 to get around the limitation on the 'in' operation
          const wordIdChunks = [];
          for (let i = 0; i < wordIds.length; i += chunkSize) {
            wordIdChunks.push(wordIds.slice(i, i + chunkSize));
          }
          console.log('Word ID Chunks:', wordIdChunks);

          const wordCollection = collection(db, "flashcards");
          console.log('Word Collection Reference:', wordCollection); // Log the collection reference

          // Fetch and concatenate word documents for each chunk
          const wordData = [];
          for (const chunk of wordIdChunks) {
            const wordQuery = query(wordCollection, where("serial_key", "in", chunk));
            console.log('Word Query:', wordQuery); // Log the query

            try {
              const wordSnapshot = await getDocs(wordQuery);
              console.log('Word Snapshot:', wordSnapshot); // Log the snapshot

              const wordDocs = wordSnapshot.docs.map(doc => doc.data());
              console.log('Fetched word documents:', wordDocs); // Log the fetched word documents

              wordData.push(...wordDocs);
            } catch (error) {
              console.error('Error fetching selected category words:', error);
            }
          }

          setFlashCardArray(wordData);
          console.log('setFlashCardArray response:', wordData);
        } catch (error) {
          console.error('Error fetching selected category words:', error);
        }
      };

      fetchWordData();
    }
  }, [selectedCategoryId]);

  return (
    <div>
      <FlashcardList flashcards={flashcardsArray} />
    </div>
  );
}

export default FetchFlashcards;  */