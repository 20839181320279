import DictionaryInputs from './DictionaryInputs';

function DictionaryComponent() {
  return (
      <div className='dictionary_container'> 
        <DictionaryInputs />
      </div>
  );
}

export default DictionaryComponent;
