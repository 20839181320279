import React, { useState } from 'react';
import { addDoc, collection } from "firebase/firestore"; // Import Firestore functions
import { db } from "./firebase";

const initialState = {
  first: "",
  last: "",
  email: ""
}

function ClientInputForm() {
  const [data, setData] = useState(initialState);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const addDocument = async (e) => {
    e.preventDefault()
    try {
      const docRef = await addDoc(collection(db, "testusers"), {
        first: data.first,
        last: data.last,
        email: data.email
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  return (
    <div className="user_input_header">
      <h2></h2>
      <form className="user_input_form">
        <div className="form-group">
          <input
            className="form-control"
            placeholder="First"
            name="first"
            type="text"
            onChange={handleChange}
            value={data.first}
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Last"
            name="last"
            type="text"
            onChange={handleChange}
            value={data.last}
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="email"
            name="email"
            type="text"
            onChange={handleChange}
            value={data.email}
          />
        </div>
        <button type="submit" onClick={addDocument}>Submit</button>
      </form>
    </div>
  );
}

export default ClientInputForm;