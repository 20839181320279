import React, { useEffect, useState } from 'react';
import DisplayAccent from './DisplayAccent';

function GetAccentInfo({ flashcard }) {
  const [accentCode, setAccentCode] = useState(null);

  /* the following useEffect should barely be necessary anymore since I get this info from the database now, 
  but I'm leaving it in to potentially give me a way to quickly add other item's accents to the raw accents text file and 
  get them from there rather than altering the database. It also get accents for the sample cards on the front page right now. */

  useEffect(() => {
    if (flashcard.accent_codes != null) {
      // Use accent_codes directly if it's available
      setAccentCode(flashcard.accent_codes);
    } else {
      // Load pitch accent information from the file
      fetch('raw_accents.txt')
        .then(response => response.text())
        .then(accents => {
          let pitchAccentInfo = null;
          let matches = accents.matchAll(/^([^\t]+(?:\t[^\t]+)?)\t(.+)$/gm);
    
          for (const match of matches) {
            const key = match[1];
    
            if (key === flashcard.full_word || key === flashcard.full_word + '\t' + flashcard.word_kana) {
              pitchAccentInfo = match[2];
              break;
            }
          }
    
          console.log('Pitch Accent Info:', pitchAccentInfo);
          setAccentCode(pitchAccentInfo);
        })
        .catch(error => {
          console.error('Error loading pitch info:', error);
        });
    }
  }, [flashcard.full_word, flashcard.word_kana, flashcard.accent_codes]);

  // Debug: Check our accentCode
  console.log('accentCode:', accentCode);

  // Return the JSX for rendering the component
  return (
    <div className="accented-word">
      <DisplayAccent flashcard={flashcard} accentCode={accentCode} />
    </div>
  );
}

export default GetAccentInfo;






